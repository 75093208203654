import { BuildingBlockTemplateDto } from "../../dto/building-blocks/building-block-template.dto";
import { BuildingBlockTemplate, BuildingBlockTemplateBase, BlockMainType, BlockSubType } from "../../models/building-blocks/building-block-template.model";
import { AudioFileMapper } from "../media/audio-file.mapper";
import { BuildingBlockTemplateUpdateDto } from "../../dto/building-blocks/building-block-template-update.dto";
import { BuildingBlockTemplateCreateDto } from "../../dto/building-blocks/building-block-template-create.dto";
import { Settings } from "../../constants/settings";

export class BuildingBlockTemplateMapper {
  public static toModel(dto: BuildingBlockTemplateDto): BuildingBlockTemplate {
    return {
      id: dto.id,
      originalLocaleId: dto.original_locale_id,
      creator: 'admin',
      title: dto.title,
      mainType: dto.main_type as BlockMainType,
      type: dto.sub_type as BlockSubType,
      categoryCode: dto.category.code,
      script: dto.script,
      estimatedDuration: dto.estimated_duration,
      therapeuticDomainCode: dto.therapeutic_domain.code,
      purposeCode: dto.purpose,
      binauralFrequencyVariationCode: dto.binaural_frequency_variation,
      binauralRangeCode: dto.binaural_range,
      file: (dto.audio_file == null) ? undefined : AudioFileMapper.toModel(dto.audio_file),
      tags: dto.tags.map(tag => tag.code),
      placeholderGroupId: dto.placeholder_group_id,
      updatedAt: new Date(dto.updated_at),
      createdAt: new Date(dto.created_at)
    }
  }

  public static toUpdateDto(model: BuildingBlockTemplate): BuildingBlockTemplateUpdateDto {
    return {
      category: model.categoryCode,
      therapeutic_domain: model.therapeuticDomainCode,
      purpose: model.purposeCode,
      binaural_frequency_variation: model.binauralFrequencyVariationCode,
      binaural_range: model.binauralRangeCode,
      tags: model.tags?.join(',') || '',
      placeholder_group_id: model.placeholderGroupId,
      estimated_duration: model.estimatedDuration
    }
  }

  public static toCreateDto(model: BuildingBlockTemplateBase, localeCode:string): BuildingBlockTemplateCreateDto {
    const dto:BuildingBlockTemplateCreateDto = {
      title: model.title,
      main_type: model.mainType,
      sub_type: model.type,
      category: model.categoryCode,
      script: model.script,
      chat_gpt_instructions: model.llmInstructions,
      estimated_duration: model.estimatedDuration,
      therapeutic_domain: model.therapeuticDomainCode!,
      purpose: model.purposeCode!,
      binaural_frequency_variation: model.binauralFrequencyVariationCode,
      binaural_range: model.binauralRangeCode,
      tags: model.tags?.join(',') || '',
      language: localeCode,
      placeholder_group_id: model.placeholderGroupId
    };

    if(model.file) {
      const f = model.file;
      dto.file_key = f.key;
      dto.file_bucket = f.bucket;
      dto.file_duration = f.duration;
      dto.file_size = f.size;
      dto.file_extension = f.extension;
    }

    return dto;
  }
}