import { VoiceDto } from "../../dto/voices/voice.dto";
import { Voice } from "../../models/voices/voice.model";

export class VoiceMapper {
  public static toModel(dto: VoiceDto): Voice {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      remoteId: dto.remote_id,
      isDefault: dto.is_default,
      localeId: dto.locale_id
    }
  }
}